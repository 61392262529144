/* eslint-disable max-len */
import { appDomain } from "../models/__variables";

export const _metadata: {
   [key: string]: {
      canonical: string;
      title: string;
      description: string;
      image: string;
   };
} = {
   "/": {
      canonical: `${appDomain}`,
      title: "Space T - Nền tảng Nội thất kết nối Chủ nhà với Công ty Nội thất",
      description:
         // eslint-disable-next-line max-len
         "Space T là Nền tảng kết nối Chủ nhà với các Công ty Nội thất uy tín, cùng hàng ngàn ưu đãi cho hơn 8000+ sản phẩm Nội thất, Trang trí, Gia dụng.",
      image: "https://spacet-release.s3.ap-southeast-1.amazonaws.com/img/seo/spacet-link-share.jpg",
   },
   "/quy-trinh-ket-noi": {
      canonical: `${appDomain}/quy-trinh-ket-noi`,
      title: "Quy trình kết nối và hoàn thiện Nội thất - Space T",
      description:
         "Chi tiết về Quy trình kết nối Chủ nhà với Công ty Nội thất, giúp gia chủ hiểu được các bước trong quá trình hoàn thiện Nội thất cho căn nhà của mình. ",
      image: "https://spacet-release.s3.ap-southeast-1.amazonaws.com/img/seo/procedure.jpg",
   },
   "/blog/thiet-ke-noi-that": {
      canonical: `${appDomain}/blog/thiet-ke-noi-that`,
      title: `Tổng hợp thiết kế nội thất cập nhật mới nhất ${new Date().getFullYear()} | Space T`,
      description:
         "Space T mang đến những thông tin hữu ích về thiết kế nội thất giúp bạn dễ dàng kiến tạo không gian sống mơ ước.",
      image: "https://spacet-release.s3.ap-southeast-1.amazonaws.com/img/seo/procedure.jpg",
   },
   "/blog/thi-cong-noi-that": {
      canonical: `${appDomain}/blog/thi-cong-noi-that`,
      title: `Tổng hợp thi công nội thất cập nhật mới nhất ${new Date().getFullYear()} | Space T`,
      description:
         "Space T mang đến những thông tin hữu ích về thi công nội thất giúp bạn dễ dàng kiến tạo không gian sống mơ ước.",
      image: "https://spacet-release.s3.ap-southeast-1.amazonaws.com/img/seo/procedure.jpg",
   },
   "/cong-trinh-thuc-te": {
      canonical: `${appDomain}/cong-trinh-thuc-te`,
      title: "Công trình hoàn thiện Nội thất thực tế",
      description:
         "Tổng hợp các Công trình Nội thất đã hoàn thành thi công thực tế. Đa dạng phong cách và loại công trình như căn hộ, nhà phố và nhiều loại công trình khác. ",
      image: "https://spacet-release.s3.ap-southeast-1.amazonaws.com/img/seo/actual-work.jpg",
   },
   "/thu-vien-thiet-ke": {
      canonical: `${appDomain}/thu-vien-thiet-ke`,
      title: "Thư viện Thiết kế Nội thất",
      description:
         "Các mẫu Thiết kế Concept Nội thất, theo nhiều phong cách thiết kế nổi bật nhất thời điểm hiện tại. Đa dạng cho căn hộ, nhà phố, và nhiều công trình khác.",
      image: "https://spacet-release.s3.ap-southeast-1.amazonaws.com/img/seo/library.jpg",
   },
   "/bao-gia": {
      canonical: `${appDomain}/bao-gia`,
      title: "Công cụ Dự toán Chi phí Thi công Nội thất - Space T",
      description:
         "Tự tính mức giá thi công nội thất trực tiếp trên Space T. Thao tác đơn giản, hiển thị giá nhanh chóng, giúp việc tư vấn và hoàn thiện nội thất nhanh chóng hơn.",
      image: "https://spacet-release.s3.ap-southeast-1.amazonaws.com/img/seo/quotation.jpg",
   },
   "/bao-gia/bep": {
      canonical: `${appDomain}/bao-gia/bep`,
      title: "Công cụ Dự toán Chi phí Thi công Bếp - Space T",
      description:
         "Công cụ ước tính giá thi công bếp được tích hợp trực tiếp trên Space T. Thao tác đơn giản, hiển thị giá nhanh chóng, giúp việc tư vấn dễ dàng, hoàn thiện nhanh chóng hơn.",
      image: "https://spacet-release.s3.ap-southeast-1.amazonaws.com/img/seo/kitchen-quotation.jpg",
   },
   "/bao-gia/tu-quan-ao": {
      canonical: `${appDomain}/bao-gia/tu-quan-ao`,
      title: "Công cụ Dự toán Chi phí Thi công Tủ quần áo - Space T",
      description:
         "Công cụ ước tính mức giá làm tủ quần áo, sử dụng trực tiếp tại Space T. Dễ thao tác, hiển thị giá chỉ sau 1s, giúp việc tư vấn và hoàn thiện nhanh chóng hơn.",
      image: "https://spacet-release.s3.ap-southeast-1.amazonaws.com/img/seo/wardrobe-quotation.jpg",
   },
   "/cac-cong-ty-noi-that": {
      canonical: `${appDomain}/cac-cong-ty-noi-that`,
      title: "Các Công ty Nội thất - Đối tác của Space T",
      description:
         "Các Công ty Nội thất giàu kinh nghiệm trong các lĩnh vực Thiết kế, Thi công, Xây dựng. Chủ nhà luôn được đảm bảo kết nối với các Công ty Nội thất uy tín, thông qua Space T.",
      image: "https://spacet-release.s3.ap-southeast-1.amazonaws.com/img/seo/partners.jpg",
   },
   "/blog": {
      canonical: `${appDomain}/blog`,
      title: "Magazine",
      description:
         "Thư viện ý tưởng bày trí, cảm hứng thiết kế và mẹo hay về nội thất, giúp mọi người dễ dàng kiến tạo không gian sống mơ ước. Tìm hiểu thêm tại Space T.",
      image: "https://spacet-release.s3.ap-southeast-1.amazonaws.com/img/seo/blog.jpg",
   },
   "/blog/phong-cach-noi-that": {
      canonical: `${appDomain}/blog/phong-cach-noi-that`,
      title: `Tổng hợp phong cách nội thất cập nhật mới nhất ${new Date().getFullYear()} | Space T`,
      description:
         "Space T mang đến những thông tin hữu ích về phong cách nội thất giúp bạn dễ dàng kiến tạo không gian sống mơ ước.",
      image: "https://spacet-release.s3.ap-southeast-1.amazonaws.com/img/seo/phong-cach-noi-that.jpg",
   },
   "/blog/kien-thuc-noi-that": {
      canonical: `${appDomain}/blog/kien-thuc-noi-that`,
      title: `Tổng hợp kiến thức nội thất cập nhật mới nhất ${new Date().getFullYear()} | Space T`,
      description:
         "Space T mang đến những thông tin hữu ích về kiến thức nội thất giúp bạn dễ dàng kiến tạo không gian sống mơ ước.",
      image: "https://spacet-release.s3.ap-southeast-1.amazonaws.com/img/seo/kien-thuc-noi-that.jpg",
   },
   "/blog/nguon-cam-hung": {
      canonical: `${appDomain}/blog/nguon-cam-hung`,
      title: `Tổng hợp nguồn cảm hứng cập nhật mới nhất ${new Date().getFullYear()} | Space T`,
      description:
         "Space T mang đến những thông tin hữu ích về nguồn cảm hứng giúp bạn dễ dàng kiến tạo không gian sống mơ ước.",
      image: "https://spacet-release.s3.ap-southeast-1.amazonaws.com/img/seo/nguon-cam-hung.jpg",
   },
   "/blog/cham-soc-nha": {
      canonical: `${appDomain}/blog/cham-soc-nha`,
      title: `Tổng hợp chăm sóc nhà cập nhật mới nhất ${new Date().getFullYear()} | Space T`,
      description:
         "Space T mang đến những thông tin hữu ích về chăm sóc nhà giúp bạn dễ dàng kiến tạo không gian sống mơ ước.",
      image: "https://spacet-release.s3.ap-southeast-1.amazonaws.com/img/seo/cham-soc-nha.jpg",
   },
   "/blog/phong-cach-song": {
      canonical: `${appDomain}/blog/phong-cach-song`,
      title: `Tổng hợp phong cách sống cập nhật mới nhất ${new Date().getFullYear()} | Space T`,
      description:
         "Space T mang đến những thông tin hữu ích về phong cách sống giúp bạn dễ dàng kiến tạo không gian sống mơ ước.",
      image: "https://spacet-release.s3.ap-southeast-1.amazonaws.com/img/seo/phong-cach-song.jpg",
   },
   "/blog/review-cong-ty": {
      canonical: `${appDomain}/blog/review-cong-ty`,
      title: `Tổng hợp review công ty cập nhật mới nhất ${new Date().getFullYear()} | Space T`,
      description:
         "Space T mang đến những thông tin hữu ích về review công ty giúp bạn dễ dàng kiến tạo không gian sống mơ ước.",
      image: "https://spacet-release.s3.ap-southeast-1.amazonaws.com/img/seo/review-cong-ty.jpg",
   },
   "/blog/thu-vien-e-book": {
      canonical: `${appDomain}/blog/thu-vien-e-book`,
      title: `Tổng hợp Thư viện E-Book cập nhật mới nhất ${new Date().getFullYear()} | Space T`,
      description:
         "Space T mang đến những thông tin hữu ích về Thư viện E-Book giúp bạn dễ dàng kiến tạo không gian sống mơ ước.",
      image: "https://spacet-release.s3.ap-southeast-1.amazonaws.com/img/seo/thu-vien-e-book.jpg",
   },

   "/blog/thu-vien-e-book/ebook-50-mau-thiet-ke-can-ho-dep": {
      canonical: `${appDomain}/blog/thu-vien-e-book/ebook-50-mau-thiet-ke-can-ho-dep`,
      title: "EBOOK 50+ Mẫu Thiết Kế Căn Hộ Đẹp & Ấn Tượng - Space T",
      description:
         "Tuyển tập 50+ mẫu căn hộ đẹp có thiết kế ấn tượng hợp thời, khơi nguồn ý tưởng thiết kế nội thất cho tổ ấm của tất cả mọi người. Xem thêm tại E-book này.",
      image: "https://spacet-release.s3.ap-southeast-1.amazonaws.com/img/seo/ebook-50-mau-thiet-ke-can-ho-dep.jpg",
   },
   "/blog/thu-vien-e-book/ebook-50-mau-thiet-ke-phong-ngu-phong-cach-hien-dai-an-tuong":
      {
         canonical: `${appDomain}/blog/thu-vien-e-book/ebook-50-mau-thiet-ke-phong-ngu-phong-cach-hien-dai-an-tuong`,
         title: "EBOOK 50+ Mẫu Thiết Kế Phòng Ngủ Đẹp Phong Cách Modern Ấn Tượng - Space T",
         description:
            "Tuyển tập 50+ mẫu phòng ngủ đẹp phong cách Modern có thiết kế ấn tượng, khơi nguồn ý tưởng thiết kế nội thất cho mọi gia chủ. Xem thêm tại E-book này.",
         image: "https://spacet-release.s3.ap-southeast-1.amazonaws.com/img/seo/ebook-50-mau-thiet-ke-phong-ngu-phong-cach-hien-dai-an-tuong.jpg",
      },
   "/blog/thu-vien-e-book/ebook-20-mau-thiet-ke-phong-ngu-phong-cach-bac-au-an-tuong":
      {
         canonical: `${appDomain}/blog/thu-vien-e-book/ebook-20-mau-thiet-ke-phong-ngu-phong-cach-bac-au-an-tuong`,
         title: "EBOOK 20+ Mẫu Thiết Kế Phòng Ngủ Đẹp Phong Cách Scandinavian Ấn Tượng - Space T",
         description:
            "Tuyển tập 20+ mẫu phòng ngủ đẹp phong cách Scandinavian có thiết kế ấn tượng, khơi nguồn ý tưởng thiết kế nội thất cho mọi gia chủ. Xem thêm tại E-book này.",
         image: "https://spacet-release.s3.ap-southeast-1.amazonaws.com/img/seo/ebook-20-mau-thiet-ke-phong-ngu-phong-cach-bac-au-an-tuong.jpg",
      },
   "/blog/thu-vien-e-book/ebook-30-mau-thiet-ke-phong-ngu-phong-cach-toi-gian-an-tuong":
      {
         canonical: `${appDomain}/blog/thu-vien-e-book/ebook-30-mau-thiet-ke-phong-ngu-phong-cach-toi-gian-an-tuong`,
         title: "EBOOK 30+ Mẫu Thiết Kế Phòng Ngủ Đẹp Phong Cách Minimalist Ấn Tượng - Space T",
         description:
            "Tuyển tập 30+ mẫu phòng ngủ đẹp phong cách Minimalist có thiết kế ấn tượng, khơi nguồn ý tưởng thiết kế nội thất cho mọi gia chủ. Xem thêm tại E-book này.",
         image: "https://spacet-release.s3.ap-southeast-1.amazonaws.com/img/seo/ebook-30-mau-thiet-ke-phong-ngu-phong-cach-toi-gian-an-tuong.jpg",
      },
   "/blog/thu-vien-e-book/ebook-100-mau-thiet-ke-phong-bep-dep-an-tuong": {
      canonical: `${appDomain}/blog/thu-vien-e-book/ebook-100-mau-thiet-ke-phong-bep-dep-an-tuong`,
      title: "EBOOK 100+ Mẫu Thiết Kế Phòng Bếp Đẹp Ấn Tượng - Space T",
      description:
         "Tuyển tập 100+ mẫu thiết kế bếp cùng 6 kiểu bố cục phòng bếp phổ biến nhất, khơi nguồn ý tưởng thiết kế nội thất cho mọi gia chủ. Xem thêm tại E-book này.",
      image: "https://spacet-release.s3.ap-southeast-1.amazonaws.com/img/seo/ebook-100-mau-thiet-ke-phong-bep-dep-an-tuong.jpg",
   },
   "/blog/thu-vien-e-book/ebook-80-mau-thiet-ke-phong-bep-chu-l-dep-an-tuong": {
      canonical: `${appDomain}/blog/thu-vien-e-book/ebook-80-mau-thiet-ke-phong-bep-chu-l-dep-an-tuong`,
      title: "EBOOK 80+ Mẫu Thiết Kế Phòng Bếp Chữ L Đẹp Ấn Tượng - Space T",
      description:
         "Tuyển tập 80+ mẫu thiết kế phòng bếp chữ L tuyệt đẹp, cực dễ áp dụng và ấn tượng, khơi nguồn ý tưởng thiết kế nội thất cho mọi gia chủ. Xem thêm tại E-book này.",
      image: "https://spacet-release.s3.ap-southeast-1.amazonaws.com/img/seo/ebook-80-mau-thiet-ke-phong-bep-chu-l-dep-an-tuong.jpg",
   },
   "/blog/thu-vien-e-book/ebook-50-mau-thiet-ke-phong-bep-chu-u-dep-an-tuong": {
      canonical: `${appDomain}/blog/thu-vien-e-book/ebook-50-mau-thiet-ke-phong-bep-chu-u-dep-an-tuong`,
      title: "EBOOK 50+ Mẫu Thiết Kế Phòng Bếp Chữ U Đẹp Ấn Tượng - Space T",
      description:
         "Tuyển tập 50+ mẫu thiết kế phòng bếp chữ U tuyệt đẹp, cực dễ áp dụng và ấn tượng, khơi nguồn ý tưởng thiết kế nội thất cho mọi gia chủ. Xem thêm tại E-book này.",
      image: "https://spacet-release.s3.ap-southeast-1.amazonaws.com/img/seo/ebook-50-mau-thiet-ke-phong-bep-chu-u-dep-an-tuong.jpg",
   },
   "/blog/thu-vien-e-book/ebook-bang-cau-hoi-khoi-dau-khi-thiet-ke-noi-that-can-ho":
      {
         canonical: `${appDomain}/blog/thu-vien-e-book/ebook-bang-cau-hoi-khoi-dau-khi-thiet-ke-noi-that-can-ho`,
         title: "EBOOK Bảng Câu Hỏi Khởi Đầu Khi Thiết Kế Nội Thất Căn Hộ - Space T",
         description:
            "Chỉ 5 -10 phút điền câu trả lời, bạn sẽ có cái nhìn tổng quan về công trình nội thất dự định hoàn thiện, giúp cho việc trao đổi, nhận tư vấn nhanh chóng, dễ dàng hơn.",
         image: "https://spacet-release.s3.ap-southeast-1.amazonaws.com/img/seo/ebook-bang-cau-hoi-khoi-dau-khi-thiet-ke-noi-that-can-ho.jpg",
      },
   "/blog/thu-vien-e-book/ebook-bang-danh-gia-chat-luong-dich-vu-don-vi-thiet-ke-thi-cong-noi-that":
      {
         canonical: `${appDomain}/blog/thu-vien-e-book/ebook-bang-danh-gia-chat-luong-dich-vu-don-vi-thiet-ke-thi-cong-noi-that`,
         title: "EBOOK Bảng Đánh Giá Chất Lượng Dịch Vụ Đơn Vị Thiết Kế Thi Công Nội Thất - Space T",
         description:
            "Bảng đánh giá này giúp bạn dễ dàng so sánh dịch vụ của các đơn vị Thiết kế - Thi công Nội thất với nhau, từ đó lựa chọn được đơn vị phù hợp nhất cho mình.",
         image: "https://spacet-release.s3.ap-southeast-1.amazonaws.com/img/seo/ebook-bang-danh-gia-chat-luong-dich-vu-don-vi-thiet-ke-thi-cong-noi-that.jpg",
      },
   "/blog/thu-vien-e-book/ebook-6-bo-cuc-phong-bep-pho-bien-va-de-ap-dung-nhat":
      {
         canonical: `${appDomain}/blog/thu-vien-e-book/ebook-6-bo-cuc-phong-bep-pho-bien-va-de-ap-dung-nhat`,
         title: "EBOOK 6 Kiểu Bố Cục Phòng Bếp Phổ Biến Và Dễ Áp Dụng Nhất - Space T",
         description:
            "Tổng hợp 6 kiểu bố cục (layout) phòng bếp phổ biến và dễ áp dụng nhất với bản vẽ minh hoạ rõ ràng, giúp bạn tìm được kiểu bếp phù hợp nhất cho tổ ấm của mình.",
         image: "https://spacet-release.s3.ap-southeast-1.amazonaws.com/img/seo/ebook-6-bo-cuc-phong-bep-pho-bien-va-de-ap-dung-nhat.jpg",
      },
   "/blog/thu-vien-e-book/ebook-90-mau-thiet-ke-phong-bep-chu-i-dep-an-tuong": {
      canonical: `${appDomain}/blog/thu-vien-e-book/ebook-90-mau-thiet-ke-phong-bep-chu-i-dep-an-tuong`,
      title: "EBOOK 90+ Mẫu Thiết Kế Phòng Bếp Chữ I Đẹp Ấn Tượng - Space T",
      description:
         "Tuyển tập hơn 90 mẫu thiết kế phòng bếp chữ I tuyệt đẹp, cực dễ áp dụng và ấn tượng, khơi nguồn ý tưởng thiết kế nội thất cho mọi gia chủ. Xem thêm tại E-book này.",
      image: "https://spacet-release.s3.ap-southeast-1.amazonaws.com/img/seo/ebook-90-mau-thiet-ke-phong-bep-chu-i-dep-an-tuong.jpg",
   },
   "/blog/thu-vien-e-book/ebook-30-mau-thiet-ke-nha-pho-dep-an-tuong": {
      canonical: `${appDomain}/blog/thu-vien-e-book/ebook-30-mau-thiet-ke-nha-pho-dep-an-tuong`,
      title: "EBOOK 30 Mẫu Thiết Kế Nhà Phố Đẹp Ấn Tượng - Space T",
      description:
         "Tuyển tập hơn 30 mẫu thiết kế nhà phố tuyệt đẹp, cực dễ áp dụng và ấn tượng, khơi nguồn ý tưởng thiết kế nội thất cho mọi gia chủ. Xem thêm tại E-book này.",
      image: "https://spacet-release.s3.ap-southeast-1.amazonaws.com/img/seo/ebook-30-mau-thiet-ke-nha-pho-dep-an-tuong.jpg",
   },
   "/blog/thu-vien-e-book/ebook-100-mau-thiet-ke-phong-tam-dep-an-tuong": {
      canonical: `${appDomain}/blog/thu-vien-e-book/ebook-100-mau-thiet-ke-phong-tam-dep-an-tuong`,
      title: "EBOOK 100+ Mẫu Thiết Kế Phòng Tắm Đẹp Ấn Tượng - Space T",
      description:
         "Tuyển tập hơn 100 mẫu thiết kế phòng tắm tuyệt đẹp, ấn tượng và dễ áp dụng, giúp khơi nguồn ý tưởng thiết kế nội thất cho mọi gia chủ. Xem thêm tại E-book này.",
      image: "https://spacet-release.s3.ap-southeast-1.amazonaws.com/img/seo/ebook-100-mau-thiet-ke-phong-tam-dep-an-tuong.jpg",
   },
   "/blog/thu-vien-e-book/ebook-120-mau-thiet-ke-goc-lam-viec-an-tuong": {
      canonical: `${appDomain}/blog/thu-vien-e-book/ebook-120-mau-thiet-ke-goc-lam-viec-an-tuong`,
      title: "EBOOK 120+ Mẫu Thiết Kế Góc Làm Việc Ấn Tượng - Space T",
      description:
         "Tuyển tập hơn 120 mẫu thiết kế góc làm việc ấn tượng và dễ áp dụng, giúp khơi nguồn ý tưởng thiết kế nội thất cho mọi gia chủ. Xem thêm tại E-book này.",
      image: "https://spacet-release.s3.ap-southeast-1.amazonaws.com/img/seo/ebook-120-mau-thiet-ke-goc-lam-viec-an-tuong.jpg",
   },
   "/to-am-cua-toi": {
      canonical: `${appDomain}/to-am-cua-toi`,
      title: "Chuyên mục: Tổ Ấm Của Tôi",
      description:
         "Cộng đồng chia sẻ hành trình kiến tạo tổ ấm của mỗi người. Nơi để bạn kể lại kinh nghiệm đúc kết được trong quá trình tạo nên không gian sống trong mơ.",
      image: "https://spacet-release.s3.ap-southeast-1.amazonaws.com/img/seo/to-am-cua-toi.jpg",
   },
   "/thiet-ke-2d-3d": {
      canonical: `${appDomain}/thiet-ke-2d-3d`,
      title: "Phần mềm thiết kế bản vẽ 2D/3D Online miễn phí, đơn giản",
      description:
         "Phần mềm thiết kế đồ họa 2D/3D online đơn giản, miễn phí (free), app bản vẽ nội thất căn hộ, mặt bằng nhà 2 tầng, nhà cấp 4 với 3 - 4 phòng ngủ trên điện thoại, máy tính.",
      image: "https://spacet-release.s3.ap-southeast-1.amazonaws.com/img/seo/thiet-ke-2d-3d.jpg",
   },
   "/feed": {
      canonical: `${appDomain}/feed`,
      title: "Social Feed | Space T",
      description:
         "Mạng xã hội nội thất kết nối những tín đồ yêu thích nội thất và chuyên gia nội thất. Chia sẻ và trao đổi cùng cộng đồng nội thất ngay.",
      image: "https://spacet-release.s3.ap-southeast-1.amazonaws.com/img/seo/spacet-link-share.jpg",
   },
   "/thu-vien-anh": {
      canonical: `${appDomain}/thu-vien-anh`,
      title: "Hình ảnh nội thất",
      description:
         "Tổng hợp hình ảnh nội thất theo các không gian phòng trong nhà. Dễ dàng tìm cảm hứng hoàn thiện nội thất theo phòng, theo các phong cách và màu sắc yêu thích.",
      image: "https://spacet-release.s3.ap-southeast-1.amazonaws.com/img/seo/hinh-anh-noi-that.jpg",
   },
   "/chuong-trinh-gioi-thieu-ban": {
      canonical: `${appDomain}/chuong-trinh-gioi-thieu-ban`,
      title: "Chương trình Giới Thiệu Bạn - Nhận Thưởng Hấp Dẫn - Space T",
      description:
         "Giới thiệu bạn bè hoàn thiện nội thất tại Space T, thưởng ngay 2 TRIỆU ĐỒNG. Tham gia ngay chỉ với 3 bước đơn giản.",
      image: `${appDomain}/img/thumbnail/landingpage-referral.jpg`,
   },
   "/shop": {
      ["canonical"]: `${appDomain}/shop`,
      ["title"]: "Space T Shop - Mua Nội Thất, Trang Trí, Gia Dụng Giá Tốt",
      ["description"]:
         "Mua sắm ONLINE 8000+ Đồ Nội thất, Trang Trí, Gia dụng Chính hãng | Tích Xu giảm đến 850K | Dễ dàng mua món đồ ưng ý.",
      ["image"]: `${appDomain}/assets/img/thumbnail/shop.jpg`,
   },
   "/shop/san-pham-ban-chay": {
      canonical: `${appDomain}/shop/san-pham-ban-chay`,
      title: "Sản phẩm bán chạy - Giá Ưu Đãi, Deal Hấp Dẫn | Space T Shop",
      description:
         "Mua Sản phẩm bán chạy chất lượng, chính hãng, giá tốt từ thương hiệu nổi tiếng. Tích Xu giảm đến 850K. Mua ngay!",
      image: `${appDomain}/assets/img/thumbnail/shop.jpg`,
   },
   "/shop/san-pham-moi": {
      canonical: `${appDomain}/shop/san-pham-moi`,
      title: "Sản phẩm mới - Giá Ưu Đãi, Deal Hấp Dẫn | Space T Shop",
      description:
         "Mua Sản phẩm mới chất lượng, chính hãng, giá tốt từ thương hiệu nổi tiếng. Tích Xu giảm đến 850K. Mua ngay!",
      image: "",
   },

   "/hoi-dap": {
      ["canonical"]: `${appDomain}/hoi-dap`,
      ["title"]: "Câu hỏi và giải đáp",
      ["description"]:
         "Hỏi đáp mọi thắc mắc liên quan đến xây dựng, thiết kế - thi công nội thất, hoàn thiện không gian sống cùng các chuyên gia và cộng đồng",
      image: "https://spacet-release.s3.ap-southeast-1.amazonaws.com/img/seo/spacet-link-share.jpg",
   },
};
